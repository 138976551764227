import React, { Fragment } from "react";
import "./about.css";

const AboutIndex = () => {
  return (
    <Fragment>      
      <div className="container-fluid page-header py-5 mb-5">
        <div className="container py-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">關於呼聲</h1>
            <nav aria-label="breadcrumb animated slideInDown">
              <ol className="breadcrumb">
                  <li className="breadcrumb-item"><a className="text-white" href="/">首頁</a></li>
                  <li className="breadcrumb-item text-white active">關於呼聲</li>
              </ol>
            </nav>
        </div>
      </div>
      
      <div className="container-fluid py-6 short">
        <div className="container">
            <div className="row">
              <div className="text-center  wow fadeInUp" data-wow-delay="0.3s">
                  <div className="bg-primary mb-3 mx-auto" style={{width: '60px', height: '2px'}}></div>
                  <h1 className="display-5 mb-5">關於呼聲</h1>
              </div>
              <div className="col-md-3 logo wow zoomIn" data-wow-delay="0.6s"><img src="img/logo__.png" alt="" /></div>
              <div class="col-md-9 wow flipInX" data-wow-delay="0.9s">
                <p>「呼聲Resound深諳網絡發聲與網紅現象崛起的力量。面對極度分眾的新市場，人人都是某樣人/事/物的粉絲與專家，每個關鍵意見都有期待聆聽的粉絲。</p>
                <blockquote><strong><span>品牌宣傳擴大機，360度行銷好夥伴</span></strong></blockquote>
                <p class="first-letra">由一群來自遊戲業、電競業、娛樂業、多媒體業，深諳宅經濟&網路鄉民的專業行銷人員組成的團隊，我們有快速轉動的創意腦、有超高行動力的執行效率、有知情達理的優秀溝通服務能力，在2020疫情時代，突破框架，成立至今，已執行超過100個行銷案例，為客戶創造滿意的行銷績效。</p>
              </div>
            </div>
        </div>
      </div>
      
      <div className="container-fluid py-6 bg-dark">
        <div className="container">
            <div className="text-center wow fadeInUp" data-wow-delay="1s">
              <div className="bg-primary mb-3 mx-auto" style={{width: '60px', height: '2px'}}></div>
              <h1 className="display-5 mb-5 text-white">呼聲優勢</h1>
            </div>
            <div className="row g-4 text-center">
              <div className="col-md-4 col-lg-4 col-sm-6 wow bounceIn" data-wow-delay="1.5s">
                  <div className="h-100 p-4 p-xl-4">
                    <div className="mb-5"><img src="img/bg/1.png" width="50px;" alt="" /></div>
                    <h2 className="text-warning">KOL網紅創意服務</h2>
                    <br />
                    <span className="color3">臺灣在地市場KOL謀合，依需求提供最佳人選、創意企劃與精准溝通，滿足各項推廣需求。</span> </div>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-6 wow bounceIn" data-wow-delay="2s">
                  <div className="h-100  p-4 p-xl-4">
                    <div className="mb-5"><img src="img/bg/2.png" width="50px;" alt="" /></div>
                    <h2 className="text-warning">全方位整合行銷</h2>
                    <br />
                    <span className="color3">專業團隊訂制產品推廣組合，市場分析，提供各類行銷專業意見。</span> </div>
              </div>
              <div className="col-md-4 col-lg-4 col-sm-12 wow bounceIn" data-wow-delay="2.5s">
                  <div className="h-100 p-4 p-xl-4">
                    <div className="mb-5"><img src="img/bg/3.png" width="50px;" alt="" /></div>
                    <h2 className="text-warning">KOL網紅流量服務</h2>
                    <br />
                    <span className="color3">針對行銷主軸及預算量身打造，網紅組合配套流量推廣，內容口碑推迭，加強整體推廣效果。</span> </div>
              </div>
            </div>
            <div className="text-center py-5"><a href="/#contact" className="btn btn-warning rounded-pill py-3 px-5">洽詢服務</a></div>
        </div>
      </div>
      
      {/*<div className="container-xxl py-6">
        <div className="container">
            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
              <div className="bg-primary mb-3 mx-auto" style={{width: '60px', height: '2px'}}></div>
              <h1 className="display-5 mb-5">呼聲團隊</h1>
            </div>
            <div className="row g-4">
              <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                  <div className="team-item">
                    <div className="overflow-hidden position-relative"> <img className="img-fluid" src="img/about/team-1.jpg" alt="" /> 

                    </div>
                    <div className="text-center p-4">
                        <h5 className="mb-3"><span className="job">J</span>o</h5>
                        <p className="mb-0 text2"><span className="job">創辦人</span> | Greative Manager</p>
                    </div>
                  </div>
              </div>
              
              <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                  <div className="team-item">
                    <div className="overflow-hidden position-relative"> <img className="img-fluid" src="img/about/team-3.jpg" alt="" /> 

                    </div>
                    <div className="text-center p-4">
                        <h5 className="mb-3"><span className="job">K</span>ira</h5>
                        <p className="mb-0 text2"><span className="job">專案經理</span> | Project Manager</p>
                    </div>
                  </div>
              </div>
              <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                  <div className="team-item">
                    <div className="overflow-hidden position-relative"> <img className="img-fluid" src="img/about/team-4.jpg" alt="" /> 

                    </div>
                    <div className="text-center p-4">
                        <h5 className="mb-3"><span className="job">N</span>ewMan</h5>
                        <p className="mb-0 text2"><span className="job">專案經理</span> | Project Manager</p>
                    </div>
                  </div>
              </div>
              <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                  <div className="team-item">
                    <div className="overflow-hidden position-relative"> <img className="img-fluid" src="img/about/team-5.jpg" alt="" /> 

                    </div>
                    <div className="text-center p-4">
                        <h5 className="mb-3"><span className="job">L</span>isa</h5>
                        <p className="mb-0 text2"><span className="job">視覺設計</span> | Creative Manager</p>
                    </div>
                  </div>
              </div>
              <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                  <div className="team-item">
                    <div className="overflow-hidden position-relative"> <img className="img-fluid" src="img/about/team-6.png" alt="" /> 

                    </div>
                    <div className="text-center p-4">
                        <h5 className="mb-3"><span className="job">S</span>helton</h5>
                        <p className="mb-0 text2"><span className="job">專案執行</span> | Project Executive</p>
                    </div>
                  </div>
              </div>
              <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                  <div className="team-item">
                    <div className="overflow-hidden position-relative"> <img className="img-fluid" src="img/about/team-7.png" alt="" /> 

                    </div>
                    <div className="text-center p-4">
                        <h5 className="mb-3"><span className="job">G</span>ladys</h5>
                        <p className="mb-0 text2"><span className="job">專案執行</span> | Project Executive</p>
                    </div>
                  </div>
              </div>
            </div>
        </div>
      </div>*/}
    </Fragment>
  );
};

export default AboutIndex;
