import React, { Fragment } from "react";

const Footer = () => {
  return (
    <Fragment>
      <div className="container-fluid text-secondary footer wow fadeIn" data-wow-delay="0.1s">
      <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-5 col-md-6">
                <div className="text-white mb-4"><img src="img/logo_.png" alt="" /></div>
                <hr className="w-25" />
                <p className="mb-2" style={{width: '75%'}}>呼聲Resound深諳網路發聲與網紅現象崛起的力量。面對極度分眾的新市場，人人都是某樣人/事/物的粉絲與專家，每個關鍵意見都有期待聆聽的粉絲。</p>
                <p className="mb-7">My Voice; My Media</p>
            </div>
            <div className="col-lg-3 col-md-6">
                <h4 className="text-white mb-4"></h4>
                <a className="btn btn-link" href="/about">關於呼聲</a> 
                <a className="btn btn-link" href="/service">服務項目</a> 
                <a className="btn btn-link" href="/work">精彩案例</a> 
                <a className="btn btn-link" href="/#kol">網紅合作</a> 
                <a className="btn btn-link" href="/videowork">短影音行銷</a> 
            </div>
            <div className="col-lg-4 col-md-6">
                <h4 className="text-white mb-4"></h4>
                <p className="mb-1">電話</p>
                <h6 className="text-light">02-8667-2958</h6>
                <p className="mb-1">信箱</p>
                <h6 className="text-light">hi@resound.global</h6>
                <p className="mb-1">地址</p>
                <h6 className="text-light">新北市新店區民權路95號7樓之3</h6>
            </div>
          </div>
      </div>
    </div>

    <div className="container-fluid py-3 copyright">
      <div className="container">
          <div className="row">
            <div className="col-md-12 text-center" style={{fontSize:'.9rem'}}> &copy; Copyright © 2023 Resound Global CO. 版權所有 </div>
          </div>
      </div>
    </div>

    </Fragment>
  );
};

export default Footer;
